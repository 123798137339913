<div
  #tableContainer
  data-test-id="caregiver-performance-grid"
  class="caregiver-performance-grid table-responsive"
  [class.hide-overflow]="(dataSource.isLoading$ | async) === false &&
            (dataSource.totalCount$ | async) === 0"
>
  <mat-table
    class="caregiver-performance-grid"
    [dataSource]="displayedColumns.length > 0 ? dataSource : null"
    matSort
    matSortActive="shiftsCompleted"
    matSortDirection="desc"
    matSortDisableClear="true"
    #sortCaregivers="matSort"
  >
    <ng-container matColumnDef="caregiver" sticky>
      <mat-header-cell *matHeaderCellDef mat-sort-header="caregiver" class="align-left">
        Caregiver
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto" class="align-left">
        <app-caregiver-name-display [caregiverDto]="caregiverDto" [subdomain]="user?.subdomain"></app-caregiver-name-display>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="turnoverRiskScore">
      <mat-header-cell *matHeaderCellDef mat-sort-header="turnoverRiskScore">
        Turnover<br>Risk
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        <div class="cell-wrapper"
             [satPopoverAnchor]="riskFactorsPopover"
             [satPopoverHover]="100">
          <sat-popover #riskFactorsPopover verticalAlign="center" horizontalAlign="after">
            <app-turnover-risk-factors-popover *appFeatureFlag="'tipc_dash_grid_turnover_risk_factors_popover'"
                                               [caregiver]="caregiverDto"></app-turnover-risk-factors-popover>
          </sat-popover>
          <app-turnover-risk [turnoverRiskScore]="caregiverDto.turnoverRiskScore" [turnoverRiskStratification]="caregiverDto.turnoverRiskStratification"> </app-turnover-risk>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tenureMonths">
      <mat-header-cell *matHeaderCellDef mat-sort-header="tenureMonths">
        Tenure
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        <app-tenure-display [tenureMonths]="caregiverDto.tenureMonths"></app-tenure-display>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="clients">
      <mat-header-cell *matHeaderCellDef mat-sort-header="clients">
        Clients
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.clients === 0 || caregiverDto.clients ===null || caregiverDto.clients === undefined ? '-' : caregiverDto.clients}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="utilization">
      <mat-header-cell *matHeaderCellDef mat-sort-header="utilization">
        Utilization<br />Worked | Wanted
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        <app-utilization-display [hoursWorked]="caregiverDto.hoursWorked" [hoursWanted]="caregiverDto.hoursWanted"></app-utilization-display>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="shiftsCompleted">
      <mat-header-cell *matHeaderCellDef mat-sort-header="shiftsCompleted">
        Shifts<br>Completed
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.shiftsCompleted === 0 || caregiverDto.shiftsCompleted === null || caregiverDto.shiftsCompleted === undefined ? '-' : caregiverDto.shiftsCompleted}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avgShiftLength">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avgShiftLength">
        Avg. Shift Length<br>(hrs)
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avgShiftLength === null
          || caregiverDto?.avgShiftLength === undefined
          || caregiverDto?.avgShiftLength === 0 ? '-' : caregiverDto.avgShiftLength | number:'1.2-2' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avgDailyMileage">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avgDailyMileage">
        Avg. Daily Mileage
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        <app-avgdailymileage-display
          [avgDailyMileage]="caregiverDto.avgDailyMileage"
          [avgDailyMileageArrow]="caregiverDto.avgDailyMileageArrow"></app-avgdailymileage-display>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avgPayRate">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avgPayRate">
        Actual Hourly<br>Pay Rate
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avgPayRate === 0 || caregiverDto.avgPayRate === null || caregiverDto.avgPayRate === undefined ? '-' : caregiverDto.avgPayRate | currency:'USD'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="shiftCancelPercent">
      <mat-header-cell *matHeaderCellDef mat-sort-header="shiftCancelPercent">
        Shifts Canceled<br>by Caregiver
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        <app-canceled-shifts [shiftCancelPercent]="caregiverDto.shiftCancelPercent"> </app-canceled-shifts>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="missedClockInPercent">
      <mat-header-cell *matHeaderCellDef mat-sort-header="missedClockInPercent">
        Missed Clock-In<br>Rate
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.missedClockInPercent === null || caregiverDto?.missedClockInPercent === undefined ? '-' : caregiverDto.missedClockInPercent | percent }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="missedClockOutPercent">
      <mat-header-cell *matHeaderCellDef mat-sort-header="missedClockOutPercent">
        Missed Clock-Out<br>Rate
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.missedClockOutPercent === null || caregiverDto?.missedClockOutPercent === undefined ? '-' : caregiverDto.missedClockOutPercent | percent }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="clientPreferenceRate">
      <mat-header-cell *matHeaderCellDef mat-sort-header="clientPreferenceRate">
        Client<br>Preference
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.clientPreferenceRate === null || caregiverDto?.clientPreferenceRate === undefined ? '-' : caregiverDto.clientPreferenceRate | percent }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tardyMinutesAverage">
      <mat-header-cell *matHeaderCellDef mat-sort-header="tardyMinutesAverage">
        Tardiness<br>(Avg. Mins)
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        <app-tardiness-display [tardyMinutesAverage]="caregiverDto.tardyMinutesAverage" [tardyMinutesAverageTrend]="caregiverDto.tardyMinutesAverageTrend"></app-tardiness-display>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fcssShifts">
      <mat-header-cell *matHeaderCellDef mat-sort-header="fcssShifts">
        FCSS Shifts
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.fcssShifts === 0 || caregiverDto.fcssShifts === null || caregiverDto.fcssShifts === undefined ? '-' : caregiverDto.fcssShifts}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fcssRequests">
      <mat-header-cell *matHeaderCellDef mat-sort-header="fcssRequests">
        FCSS Requests
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.fcssRequests === 0 || caregiverDto.fcssRequests === null || caregiverDto.fcssRequests === undefined ? '-' : caregiverDto.fcssRequests}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="highHospRiskPercent">
      <mat-header-cell *matHeaderCellDef mat-sort-header="highHospRiskPercent">
        High Hosp.<br>Risk Clients
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        <app-high-hosp-risk [highHospRiskPercent]="caregiverDto.highHospRiskPercent"> </app-high-hosp-risk>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avaNumberOfVisits">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avaNumberOfVisits">
        Total<br>Logins
        <img src="../../../../assets/icons/ava-col-icon.svg" class="ava-col-icon">
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avaNumberOfVisits === null || caregiverDto.avaNumberOfVisits === undefined ? '-' : caregiverDto.avaNumberOfVisits}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avaPointsEarned">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avaPointsEarned">
        Points<br>Earned
        <img src="../../../../assets/icons/ava-col-icon.svg" class="ava-col-icon">
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avaPointsEarned === null || caregiverDto.avaPointsEarned === undefined ? '-' : caregiverDto.avaPointsEarned}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avaSpotPointsReceived">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avaSpotPointsReceived">
        Spot Rewards<br>Received
        <img src="../../../../assets/icons/ava-col-icon.svg" class="ava-col-icon">
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avaSpotPointsReceived === null || caregiverDto.avaSpotPointsReceived === undefined ? '-' : caregiverDto.avaSpotPointsReceived}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avaPointsRedeemed">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avaPointsRedeemed">
        Points<br>Spent
        <img src="../../../../assets/icons/ava-col-icon.svg" class="ava-col-icon">
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avaPointsRedeemed === null || caregiverDto.avaPointsRedeemed === undefined ? '-' : caregiverDto.avaPointsRedeemed}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avaGoalCompletionRate">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avaGoalCompletionRate">
        Goal Completion<br>Rate
        <img src="../../../../assets/icons/ava-col-icon.svg" class="ava-col-icon">
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avaGoalCompletionRate === null || caregiverDto.avaGoalCompletionRate === undefined ? '-' : caregiverDto.avaGoalCompletionRate | percent}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="avaReferralsSubmitted">
      <mat-header-cell *matHeaderCellDef mat-sort-header="avaReferralsSubmitted">
        Referrals<br>Submitted
        <img src="../../../../assets/icons/ava-col-icon.svg" class="ava-col-icon">
      </mat-header-cell>
      <mat-cell *matCellDef="let caregiverDto">
        {{ caregiverDto.avaReferralsSubmitted === null || caregiverDto.avaReferralsSubmitted === undefined ? '-' : caregiverDto.avaReferralsSubmitted}}
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></mat-header-row>
    <mat-row
      *matRowDef="let caregiverDto; columns: displayedColumns"
    ></mat-row>
  </mat-table>
  
  <app-caregiver-performance-grid-empty-state
    *ngIf="
            ((dataSource.isLoading$ | async) === false &&
            (dataSource.totalCount$ | async) === 0) || displayedColumns.length === 0
          "
  >
  </app-caregiver-performance-grid-empty-state>
</div>
<!-- Loading spinner -->
<div
  data-test-id="loading-indicator"
  class="spinner-container"
  *ngIf="dataSource.isLoading$ | async"
>
  <mat-spinner aria-label="Loading caregivers"></mat-spinner>
</div>
<mat-paginator
  class="paginator"
  [length]="dataSource.totalCount$ | async"
  [pageSize]="25"
  [pageSizeOptions]="[10, 25, 50, 100, 250]"
  showFirstLastButtons
>
</mat-paginator>
